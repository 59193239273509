import {setI18n} from '@/libs/i18n'
import Swal from 'sweetalert2'

export const EXCRUDE_EXT = ["exe","sh","bat","asp","aspx","htm","html","asa","phtml","php","php3","php4","php5","inc","jsp","jspx","jsw","jsv","jspf","pl","pm","cgi","lib","cfm","cfml","cfc","dbm","pif","application","gadget","msi","msp","com","scr","hta","cpl","msc","jar","cmd","vb","vbs","vbe","js","jse","ws","wsf","wsc","wsh","ps1","ps1xml","ps2","ps2xml","psc1","psc2","msh","msh1","msh2","mshxml","msh1xml","msh2xml","scf","lnk","inf","reg"]

export async function getLang(cdList, column) {
  const i18n = await setI18n()
  let col = column ?? 'text';
  for(let i=0, listSize=cdList.length; listSize > i; i++){
    const element = cdList[i];
    element[col] = i18n.t(element.mulLangCd)
  }
  return cdList;
}
  
export async function setNullData(cdList, objNdata) {
  if(!!objNdata){
    const nullValue = objNdata.value;
    if(objNdata.text){
      cdList.unshift({value:nullValue, text:objNdata.text})
    }else{
      const i18n = await setI18n()
      if(objNdata.type=="0"){
        cdList.unshift({value:nullValue, text:i18n.t("all")})
      } else if(objNdata.type=="1"){
        cdList.unshift({value:nullValue, text:i18n.t("select")})
      } else {
        cdList.unshift({value:nullValue, text:""})
      }
    }
  }
}

export function guid() {
  function _s4() {
    const scArray = new Uint32Array(1);
    window.crypto.getRandomValues(scArray);
    const scRandomValue = scArray[0] % 0x10000;
    var ranNum = scRandomValue.toString(16).padStart(4,'0');
    return ranNum;
  }
  return _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
}

export function isExcurdeExt(strExt){
  const strExcrudeExt = EXCRUDE_EXT.join('|');
  const regex = new RegExp(`(.*?)\.(${strExcrudeExt})$`);

  return regex.test(strExt);
}

export function downloadResponse(response){
  const name = decodeURI(response.headers['content-disposition']
         .split('fileName=')[1].replace(/;/g, ''));
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const ext = name.substring(name.lastIndexOf('.'));
  if(isExcurdeExt(ext)){
    setI18n().then(i18n => {
      return Swal.fire({title:i18n.t('error'), text:i18n.t('message.F000009'), icon:'error'}).then(()=>{
        return false
      })
    })
    return false
  }
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function IsJsonString(str) {
  try {
    var json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
}

export function decodeHTMLEntities(str) {
  if(str !== undefined && str !== null && str !== '') {
      str = String(str);
      str = str.replace(/&#39;/g, "'");
      str = str.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
  return str;
}

export function nullCheck(str,m) {
  if (str == null || str == '') {
    Swal.fire('오류',  m + "을(를) 입력하십시요." , 'error');
    return true 
  }else{
    return false 
  }
}

export function isJson(str) {
  try {
    if(str != null && str != ''){
      str = str.replaceAll("'", "\"");
    }
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}